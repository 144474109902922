import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { H3, H4 } from 'components/Atom/Typo';
import ModalHeader from 'components/Molecule/ComponentHeader/ModalHeader';
import { useEffect } from 'react';

const Component = styled.div<{ isModalOpen: boolean }>`
  ${(props) => {
    if (props.isModalOpen) {
      return css`
        display: flex;
      `;
    }
    return css`
      display: none;
    `;
  }}
  position: fixed;
  flex-direction: column;

  left: 50%;
  top: 100px;
  transform: translate(-50%, 0%);
  background-color: white;
  border-radius: 24px;
  padding: 24px;

  min-width: 360px;
  min-height: 500px;

  max-width: 713px;
  max-height: 700px;

  width: 100%;
  height: calc(100% - 100px);

  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
  z-index: 999;
`;

const BackgroundBlur = styled.div<{ isModalOpen: boolean }>`
  ${(props) => {
    if (props.isModalOpen) {
      return css`
        display: block;
      `;
    }
    return css`
      display: none;
    `;
  }}
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.6;
  left: 0px;
  top: 0px;
  z-index: 998;
`;

const Body = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: scroll;
`;

interface ModalProps {
  title: string;
  body?: React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal = ({ title, body, isModalOpen, setIsModalOpen }: ModalProps) => {
  return (
    <>
      <Component isModalOpen={isModalOpen}>
        <ModalHeader title={title} setIsModalOpen={setIsModalOpen} />
        <Body>{body}</Body>
      </Component>
      <BackgroundBlur isModalOpen={isModalOpen} />
    </>
  );
};

export default Modal;
