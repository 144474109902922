import React from 'react';
import styled from '@emotion/styled';
import { H3 } from 'components/Atom/Typo';
import { IconClose } from 'components/Atom/Icon';
import Button from 'components/Atom/Button';

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;
  width: 100%;

  ${H3} {
    line-height: 1.4;
    height: 29px;
  }
`;

const CloseButton = styled(Button)`
  width: 24px;
  height: 24px;
  background-color: transparent;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${(props) => props.theme.colors.grey500};
    }
  }
`;

interface ModalHeaderProps {
  title: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

const ModalHeader = ({
  title,
  className,
  setIsModalOpen,
}: ModalHeaderProps) => {
  return (
    <Component className={className}>
      <H3>{title}</H3>
      <CloseButton
        onClick={() => {
          setIsModalOpen(false);
        }}
      >
        <IconClose />
      </CloseButton>
    </Component>
  );
};

export default ModalHeader;
