import React, { useState } from 'react';
import styled from '@emotion/styled';
import Img, { FluidObject } from 'gatsby-image';
import { useEffect } from 'react';
import { css } from '@emotion/react';

const Component = styled(Img)<{ isChrome: boolean }>`
  width: 100%;
  height: 100%;

  img {
    object-position: top !important;
    ${(props) => {
      if (props.isChrome) {
        return css`
          image-rendering: -webkit-optimize-contrast;
        `;
      }
      return css``;
    }}/* width: fit-content !important;
    height: fit-content !important;; */
  }
`;

interface ImageProps {
  img: FluidObject;
  className?: string;
}

const Image = ({ img, className }: ImageProps) => {
  const [isChrome, setIsChrome] = useState(false);
  useEffect(() => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    if (isChrome) {
      setIsChrome(true);
    }
  }, []);
  return (
    <Component
      className={`voda_fluid_img ${className || ''}`}
      fluid={img}
      isChrome={isChrome}
      alt=""
    />
  );
};

export default Image;
