import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

const Component = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  :hover {
    color: white;
  }
`;

interface LinkButtonProps {
  to: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onMouseOver?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

const LinkButton = ({
  to,
  className,
  children,
  onClick,
  onMouseOver,
}: LinkButtonProps) => {
  return (
    <Component
      className={className}
      rel="canonical"
      to={to}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      {children}
    </Component>
  );
};

export default LinkButton;
