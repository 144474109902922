import React, { useState } from 'react';
import styled from '@emotion/styled';
import FooterList from 'components/Molecule/LinkList/FooterList';
import { ContainerGrid, ItemGrid } from 'components/Atom/DefualtLayout';
import { LogoPlani, LogoSignature } from 'components/Atom/Icon';
import FooterETCLink from 'components/Molecule/LinkList/FooterETCLink';
import { P4 } from 'components/Atom/Typo';
import Anchor from 'components/Atom/Anchor';
import Modal from '../Modal';
import PrivacyPolicy from 'components/Molecule/PolicyText/PrivacyPolicy';
import TermsOfUse from 'components/Molecule/PolicyText/TermsOfUse';

const Component = styled.div`
  width: 100%;
  height: 513px;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.grey100};
`;

const MaxWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const TopWrapper = styled.div`
  padding: 60px 0px;
  width: 100%;
  height: 296px;
  display: flex;
`;

const AddressWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  ${P4} {
    color: ${(props) => props.theme.colors.grey500};
    line-height: 1.5;
  }
`;

const BottomWrapper = styled.div`
  width: 100%;
  height: 216px;
  padding: 60px 0px;
`;

const LogoWrapper = styled.div`
  svg {
    height: 60px;
    width: 56px;
  }
`;

const LogoWrapperPlani = styled(Anchor)`
  justify-content: flex-start;
  width: fit-content;
  svg {
    height: 20px;
    width: 74px;
  }
`;

const Footer = () => {
  const [isModalOpenPrivacy, setIsModalOpenPrivacy] = useState(false);
  const [isModalOpenTerms, setIsModalOpenTerms] = useState(false);

  return (
    <Component>
      <MaxWrapper>
        <TopWrapper>
          <ContainerGrid spacing={'xsm'}>
            <ItemGrid desktop={4}>
              <LogoWrapper>
                <LogoSignature />
              </LogoWrapper>
            </ItemGrid>
            <ItemGrid desktop={2}>
              <FooterList
                title="기능"
                list={[
                  {
                    title: '웹 사이트 사용 분석',
                    to: '/functions/usageAnalysis',
                  },
                  {
                    title: '사용 환경 분석',
                    to: '/functions/environmentAnalysis',
                  },
                  { title: '사용성(UX) 분석', to: '/functions/uxAnalysis' },
                  // { title: 'AIVORY 검색 이용 분석', to: '/functions/aivory' },
                  // { title: '퍼널 분석', to: 'functions/funnelAnalysis' },
                  // { title: 'A/B 테스트', to: 'functions/abTest' },
                ]}
              />
            </ItemGrid>
            <ItemGrid desktop={2}>
              <FooterList
                title="적용"
                list={[
                  { title: '정부/공공기관 홈페이지', to: '/apply/agency' },
                  { title: '기업 홈페이지', to: '/apply/company' },
                  { title: '온라인 쇼핑몰', to: '/apply/mall' },
                ]}
              />
            </ItemGrid>
            <ItemGrid desktop={2}>
              <FooterList title="가격" to="/pricing" />
            </ItemGrid>
            <ItemGrid desktop={2}>
              <FooterList
                title="문의"
                list={[
                  { title: '문의', to: '/contact/form' },
                  { title: '자료실', to: '/contact/download' },
                ]}
              />
            </ItemGrid>
          </ContainerGrid>
        </TopWrapper>
        <Split />
        <BottomWrapper>
          <ContainerGrid spacing={'xsm'}>
            <ItemGrid desktop={4}>
              <LogoWrapperPlani href="http://plani.co.kr/">
                <LogoPlani />
              </LogoWrapperPlani>
            </ItemGrid>
            <ItemGrid desktop={8}>
              <FooterETCLink
                setIsModalOpenTerms={setIsModalOpenTerms}
                setIsModalOpenPrivacy={setIsModalOpenPrivacy}
              />
              <AddressWrapper>
                <P4>
                  대전광역시 유성구 문지로 282-10 | 042-934-3508 | 너드팩토리
                </P4>
                <P4>2021 © Plani. All Rights Reserved.</P4>
              </AddressWrapper>
            </ItemGrid>
          </ContainerGrid>
        </BottomWrapper>
        <Modal
          title="AIVORY VODA 개인정보처리방침"
          isModalOpen={isModalOpenPrivacy}
          setIsModalOpen={setIsModalOpenPrivacy}
          body={<PrivacyPolicy />}
        />
        <Modal
          title="AIVORY VODA 이용약관"
          isModalOpen={isModalOpenTerms}
          setIsModalOpen={setIsModalOpenTerms}
          body={<TermsOfUse />}
        />
      </MaxWrapper>
    </Component>
  );
};

export default Footer;
