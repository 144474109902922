import React from 'react';
import styled from '@emotion/styled';
import { P3 } from 'components/Atom/Typo';
import LinkButton from 'components/Atom/Link';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
    line-height: 1.43;
  }
`;

const Link = styled(LinkButton)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }

  :hover {
    ${P3} {
      font-weight: 700;
      color: ${(props) => props.theme.colors.grey800};
    }
  }
`;

interface NavLinkListProps {
  className?: string;
  list?: {
    title: string;
    to: string;
  }[];
  onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const NavLinkList = ({ className, list, onMouseOver }: NavLinkListProps) => {
  return (
    <Component className={className} onMouseOver={onMouseOver}>
      {list &&
        list.map((element) => {
          return (
            <Link key={element.title} to={element.to}>
              <P3>{element.title}</P3>
            </Link>
          );
        })}
    </Component>
  );
};

export default NavLinkList;
