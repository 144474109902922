import styled from '@emotion/styled';

export const H1 = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.grey800};
`;

export const H2 = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.grey800};
`;

export const H3 = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.grey800};
`;

export const H4 = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P1 = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P2 = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P3 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P4 = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P5 = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey800};
`;
