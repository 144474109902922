import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { P1 } from 'components/Atom/Typo';

const Component = styled.div`
  ${P1} {
    font-size: 28px;
    line-height: 1.43;
  }
`;

interface TemplateTitleProps {
  children?: ReactNode;
  className?: string;
}

const TemplateSubTitle = ({ children, className }: TemplateTitleProps) => {
  return (
    <Component className={className}>
      <P1>{children}</P1>
    </Component>
  );
};

export default TemplateSubTitle;
