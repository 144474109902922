import React from 'react';
import styled from '@emotion/styled';
import { H4 } from 'components/Atom/Typo';
import Button from 'components/Atom/Button';

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const PolicyButton = styled(Button)`
  background-color: transparent;
  width: fit-content;
  height: 21px;
  ${H4} {
    font-size: 12px;
    color: ${(props) => props.theme.colors.grey500};
    line-height: 1.83;
  }
`;

const Split = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${(props) => props.theme.colors.grey500};
  margin-left: 8px;
  margin-right: 8px;
`;

interface FooterETCLinkProps {
  setIsModalOpenPrivacy: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpenTerms: React.Dispatch<React.SetStateAction<boolean>>;
}

const FooterETCLink = ({
  setIsModalOpenPrivacy,
  setIsModalOpenTerms,
}: FooterETCLinkProps) => {
  return (
    <Component>
      <PolicyButton
        onClick={() => {
          setIsModalOpenPrivacy(true);
        }}
      >
        <H4>개인정보취급방침</H4>
      </PolicyButton>
      <Split />
      <PolicyButton
        onClick={() => {
          setIsModalOpenTerms(true);
        }}
      >
        <H4>이용약관</H4>
      </PolicyButton>
    </Component>
  );
};

export default FooterETCLink;
