import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { H2 } from 'components/Atom/Typo';

const Component = styled.div`
  ${H2} {
    font-size: 28px;
    line-height: 1.43;
  }
`;

interface TemplateTitleProps {
  children?: ReactNode;
  className?: string;
}

const TemplateTitle = ({ children, className }: TemplateTitleProps) => {
  return (
    <Component className={className}>
      <H2>{children}</H2>
    </Component>
  );
};

export default TemplateTitle;
