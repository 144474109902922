import React from 'react';
import styled from '@emotion/styled';
import LinkButton from 'components/Atom/Link';
import { IconArrowRight } from 'components/Atom/Icon';
import { H4, P3 } from 'components/Atom/Typo';
import { css } from '@emotion/react';
import Anchor from 'components/Atom/Anchor';

const Component = styled.div<{ isBackGround?: boolean }>`
  width: 204px;
  height: 52px;
  border-radius: 26px;
  border: solid 1px ${(props) => props.theme.colors.grey900};
  background-color: transparent;

  ${H4} {
    font-size: 16px;
  }

  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    svg {
      width: 16px;
      margin-left: 8px;
      path {
        fill: ${(props) => props.theme.colors.grey800};
      }
    }
  }

  ${(props) => {
    if (props.isBackGround) {
      return css`
        ${H4} {
          color: white;
        }
        ${P3} {
          color: white;
        }
        border: none;
        background-color: ${props.theme.colors.primary};
        a {
          svg {
            path {
              fill: white;
            }
          }
        }
      `;
    }
    return css`
      :hover {
        ${H4} {
          color: white;
        }
        ${P3} {
          color: white;
        }
        border: none;
        background-color: ${props.theme.colors.primary};
        a {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    `;
  }}
`;

interface DetailButton {
  to: string;
  title: string;
  isBold?: boolean;
  isBackGround?: boolean;
  isExternal?: boolean;
  className?: string;
}

const MoreButton = ({
  isBackGround,
  to,
  title,
  className,
  isExternal,
  isBold,
}: DetailButton) => {
  return (
    <Component
      className={`voda_more_info_button ${className || ''}`}
      isBackGround={isBackGround}
    >
      {isExternal ? (
        <Anchor href={to}>
          {isBold ? <H4>{title}</H4> : <P3>{title}</P3>}

          <IconArrowRight />
        </Anchor>
      ) : (
        <LinkButton to={to}>
          {isBold ? <H4>{title}</H4> : <P3>{title}</P3>}
          <IconArrowRight />
        </LinkButton>
      )}
    </Component>
  );
};

export default MoreButton;
