import React from 'react';
import styled from '@emotion/styled';

import TemplateTitle from 'components/Molecule/Title/TemplateTitle';
import TemplateSubTitle from 'components/Molecule/Title/TemplateSubTitle';
import CheckLabelList from 'components/Organisms/Desc/CheckLabelList';
import MoreButton from 'components/Molecule/MoreButton';
import LinkButton from 'components/Atom/Link';

const Component = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.grey900};
`;

const TitleWrapper = styled(TemplateTitle)`
  h2 {
    color: white;
  }
`;

const SubTitleWrapper = styled(TemplateSubTitle)`
  p {
    color: white;
  }
  margin-bottom: 8px;
`;

const ButtonWrapper = styled(MoreButton)`
  margin-top: 80px;
`;

const WhyChooseVoda = () => {
  return (
    <Component>
      <SubTitleWrapper>VODA를 선택해야하는 이유?</SubTitleWrapper>
      <TitleWrapper>직접 경험하고 확인하세요!</TitleWrapper>
      <CheckLabelList
        data={[
          { title: 'GS 품질 인증 솔루션' },
          { title: '지속적인 업데이트' },
          { title: '고객 맞춤형 서비스' },
          // { title: '검색솔루션 AIVORY 연계' },
          // { title: '미래예측' },
        ]}
      />
      <ButtonWrapper
        isBold
        isBackGround
        to="/contact/form"
        title="VODA 문의하기"
        isExternal={false}
      />
    </Component>
  );
};

export default WhyChooseVoda;
