import React from 'react';
import styled from '@emotion/styled';

const Component = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  :hover {
    color: white;
  }
`;

interface LinkButtonProps {
  href: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Anchor = ({ href, className, children, onClick }: LinkButtonProps) => {
  return (
    <Component
      className={className}
      href={href}
      onClick={onClick}
      target="_blank"
    >
      {children}
    </Component>
  );
};

export default Anchor;
