import React from 'react';
import styled from '@emotion/styled';
import { IconCheckCircle } from 'components/Atom/Icon';
import { P2 } from 'components/Atom/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;

  ${P2} {
    font-size: 18px;
    color: white;
    line-height: 1.22;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path {
      fill: white;
    }
  }
`;

interface CheckLabelProps {
  className?: string;
  title: string;
}

const CheckLabel = ({ className, title }: CheckLabelProps) => {
  return (
    <Component className={className}>
      <IconCheckCircle />
      <P2>{title}</P2>
    </Component>
  );
};

export default CheckLabel;
