import React from 'react';
import styled from '@emotion/styled';
import { H4, P3 } from 'components/Atom/Typo';

const Component = styled.div`
  width: 100%;

  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
    line-height: 22px;
  }

  ${H4} {
    font-size: 14px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.grey600};
    margin-top: 24px;
  }
`;

const TermsOfUse = () => {
  return (
    <Component>
      <H4>제 1조. 목적</H4>
      <P3>
        본 약관은 (주)플랜아이(이하 “회사”)가 제공하는 실시간 웹사이트 UX분석
        서비스인 AIVORY VODA(이하 “서비스”)를 이용함에 있어 회사와 회원의 권리,
        의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
      </P3>

      <H4>제 2조. 용어의 정의</H4>
      <P3>
        서비스 - 회사가 제공하는 실시간 웹사이트 UX분석 서비스인 AIVORY VODA를
        말합니다. 웹사이트 - 회사가 제공하는 서비스를 회원이 이용할 수 있는
        웹사이트(voda.nerdfactory.ai)를 말합니다. 회원 - 본 약관에 따라 회사와
        서비스 이용계약을 체결한 개인이나 법인 또는 법인에 준하는 단체 사용자 -
        회원이 서비스 이용을 위해 회사의 프로그램을 설치하여 사용하는 주체를
        말합니다. 이용요금 - 서비스 사용자가 서비스를 사용한 대가에 따라 회사가
        청구한 금액 오픈 베타테스트 - 회사가 정식 서비스를 개시함에 앞서
        회원들에게 시범적으로 제공하는 서비스를 말합니다. 제 2조에서 정의 되지
        않은 약관상의 용어의 의미는 관계 법령 및 기타 일반적인 상관례에
        의합니다.
      </P3>

      <H4>제 3조. 약관의 효력 및 개정</H4>
      <P3>1. 회사는 약관을 회원들이 알 수 있도록 웹사이트에 게시합니다.</P3>
      <P3>
        2. 본 약관에 명시되지 않은 사항에 대해서는 약관의 규제에 관한 법률,
        전자문서 및 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보 보호
        등에 관한 법률, 소비자 기본법, 전자상거래 등에서의 소비자보호에 관한
        법률 등 관계법령 및 회사가 정한 서비스의 세부이용지침 등의 규정 및 일반
        상관례에 의합니다.
      </P3>
      <P3>
        3. 본 약관은 수시로 개정 가능합니다. 본 약관을 개정하고자 할 경우,
        회사는 개정된 약관을 적용하고자 하는 날(이하 “효력 발생일”)로 부터 7일
        이전(회원에게 불리한 약관 개정의 경우 30일 이전)에 약관이 개정된다는
        사실과 개정된 내용 등을 웹사이트 게시, 전자우편, 휴대전화 메시지, 서면
        통보 중 1가지 이상의 방법을 통하여 회원에게 공지합니다.
      </P3>
      <P3>
        4. 개정된 약관은 원칙적으로 그 효력 발생일로부터 장래에 향하여
        유효합니다.
      </P3>
      <P3>
        5. 본 약관의 개정과 관련하여 이의가 있는 회원은 탈퇴할 수 있습니다. 단,
        이의가 있음에도 제3항의 정해진 바에 따른 회사의 고지가 있은 후 30일
        이내에 회원탈퇴를 하지 않은 회원은 개정 약관에 동의한 것으로 간주합니다.
      </P3>
      <P3>
        6. 회사는 필요한 경우 서비스 이용과 관련된 서비스 운영정책을 정하여 이를
        웹사이트 등을 통하여 공지할 수 있습니다.
      </P3>

      <H4>제 4조. 회원가입 및 계약의 성립</H4>
      <P3>
        이용자의 개인정보는 회원 탈퇴(서비스 이용계약 해지)시까지 위 이용목적을
        위하여 보관 및 이용됩니다. 다만, 아래 각 항목에 해당하는 경우에는 명시한
        기간 동안 보관할 수 있으며, 그 외 다른 목적으로는 사용하지 않습니다.
      </P3>

      <P3>
        1. 서비스를 이용하고자 하는 자는 회사가 정한 회원가입 절차에 의해 본
        약관과 ‘개인정보 수집 및 이용 동의서’에 동의함으로써 회원가입을
        신청합니다.
      </P3>
      <P3>
        2. 회사는 제 1항과 같은 회원가입 신청에 대하여 회원가입을 승낙함을
        원칙으로 합니다. 다만, 다음 각 호에 해당하는 신청에 대하여 승낙을 하지
        않거나 사후에 가입계약을 해지할 수 있습니다.
      </P3>
      <P3>
        - 회원이 본 약관에 의하여 과거에 회원자격을 상실하였던 적이 있는 경우
        (다만 회사로부터 재가입 승낙을 얻은 경우는 예외로 함)
      </P3>
      <P3>- 실명이 아니거나 타인의 명의를 이용한 경우</P3>
      <P3>- 회원 가입 시 등록 내용에 허위, 기재누락, 오기가 있는 경우</P3>
      <P3>- 만 14세 미만 아동이 법정대리인(부모 등)의 동의 없이 가입한 경우</P3>
      <P3>
        - 기타 회사가 회원에게 서비스를 제공하는 것이 부적합 또는 곤란한 경우
      </P3>
      <P3>
        3. 회원가입계약의 성립시기는 회사가 가입완료를 신청절차 상에서 표시한
        시점으로 합니다.
      </P3>

      <H4>제 5조. 회원의 계정 관리에 대한 의무</H4>

      <P3>
        1. 회원의 서비스 계정에 대한 관리책임은 회원에게 있으며, 이를 제 3자가
        사용하도록 허락할 수 없습니다.
      </P3>
      <P3>
        2. 회원은 계정이 도용되거나 제 3자가 사용하고 있음을 인지한 경우 이를
        즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
      </P3>
      <P3>
        3. 제 2항의 경우 회원이 회사에 그 사실을 통지하지 않거나 통지한 경우에도
        회사의 안내에 따르지 않아 발생하는 불이익에 대하여 회사는 어떠 한 책임도
        부담하지 않습니다.
      </P3>
      <P3>
        4. 회사는 서비스 계정의 불법적 이용을 막기 위해 동시접속 제한, 접속 PC
        지정 동의 서비스 이용 제한 조치를 취할 수 있으며, 회원은 회사의 해당
        조치에 적극 협조해야 합니다.
      </P3>

      <H4>제 6조. 회원 정보 변경</H4>

      <P3>
        1. 회원은 사이트에서 언제든지 본인의 정보를 열람하고 수정할 수 있습니다.
      </P3>
      <P3>
        2. 회원은 서비스 이용신청 시 기재한 사항이 변경되었을 경우, 사이트에서
        수정을 해야 하며 회원의 정보를 변경하지 아니하여 발생되는 문제의 책임은
        회원에게 있습니다.
      </P3>

      <H4>제 7조. 회원에 대한 통지</H4>

      <P3>
        1. 회사는 본 약관에 별도의 규정이 없는 한, 회원이 등록한 전자우편 주소
        또는 전화번호로 전자우편 통보, 유선 통보 및 기타의 방법으로 통지 할 수
        있습니다.
      </P3>
      <P3>
        2. 회사는 회원의 연락처 미기재, 변경 등 귀책사유로 인하여 개별 통지가
        어렵거나 불특정 다수 회원에 대한 통지를 하는 경우 1주일 이상 웹사 이트에
        게시함으로써 개별 통지에 갈음할 수 있습니다.
      </P3>

      <H4>제 8조. 서비스 제공</H4>

      <P3>
        1. 서비스 사용요금의 지불에 대한 서비스 제공 내용은 서비스 분석을 위한
        스크립트의 제공, 스크립트를 이용하여 생성된 분석내용의 보존 그리고 그
        분석내용을 이용한 리포트의 제공에 한합니다.
      </P3>
      <P3>
        2. 회원으로 가입하여 서비스를 이용하고자 하는 회원은 회사에서 제공하는
        스크립트를 설치해야하며 스크립트를 페이지에 설치하는 작업등은 서비스
        제공내역에 포함되지 않습니다.
      </P3>
      <P3>
        3. 서비스는 회원이 서비스에 등록한 URL 이나 스크립트를 설치한 도메인을
        기반으로 발생하는 모든 데이터를 수집합니다.
      </P3>
      <P3>
        4. 회사는 이 약관에 따라 회원에게 서비스를 제공하며 회사 정책에 따라
        플랜을 등급별로 구분하여 서비스를 차등 제공할 수 있습니다. 회원은 자신의
        선택에 따라 회사가 부가적으로 제공하는 서비스를(이하 “유료서비스”)를
        이용할 수 있습니다.
      </P3>

      <H4>제 9조. 서비스 변경 및 중단</H4>

      <P3>
        1. 회사는 운영상, 기술상 필요 등 상당한 이유가 있는 경우 서비스의 내용,
        운영상 또는 기술상 사항 등을 변경할 수 있습니다.
      </P3>
      <P3>
        2. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절,
        또는 그밖에 운영상 또는 기술상 합리적인 사유가 있는 경우 서비스 의
        제공을 일시적으로 중단할 수 있습니다.
      </P3>
      <P3>
        3. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있습니다.
      </P3>
      <P3>
        4. 본 조에 따라 서비스를 변경 또는 중단하는 경우 회사는 제 7조에 정한
        방법으로 이러한 사실(서비스 변경일 또는 중단일, 정기점검시간 등 정보를
        포함) 및 그 사유를 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수
        없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
      </P3>
      <P3>
        5. 회원의 서비스 이용으로 인해 회사가 제공하는 전체 서비스에 영향을 미칠
        것으로 판단되는 상황이 발생할 경우 회사는 해당 회원의 서비스를
        임시적으로 제한할 수 있습니다.
      </P3>
      <P3>
        6. 회사는 관련 법령에 특별한 규정이 없는 한 무료로 제공되는 서비스의
        일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
        있으며, 이에 대하여 회원에게 별도의 보상을 하지 않습니다.
      </P3>

      <H4>제 10조. 서비스 이용계약의 해지</H4>

      <P3>
        1. 사용자의 귀책사유로 서비스를 사용하지 않을 경우, 서비스 기간에 대하여
        납부한 서비스 요금은 환불되지 않습니다.
      </P3>
      <P3>
        2. 회사의 귀책사유로 서비스를 중지하게 될 경우, 남은 서비스기간에
        해당하는 서비스 요금을 환불합니다.
      </P3>
      <P3>
        3. 서비스 약관 등의 변경에 따라 새로운 약관에 동의할 수 없는 경우
        서비스의 해지를 신청 할 수 있습니다.
      </P3>
      <P3>
        4. 회사는 다음의 경우에 사용자의 합의 없이 또는 사전통보 없이 일방적으로
        서비스를 해지 할 수 있습니다.
        <P3>
          - 제 4조 제 2항에 규정된 존재하여 서비스 제공이 중단되고, 서비스 제공
          중단 기간 내에 해소되지 아니한 경우
        </P3>
        <P3>- 제 14조 제 2항에 규정된 의무를 위반한 경우</P3>
        <P3>
          - 회원이 서비스 이용 요금의 지급을 지체하여 회사로부터 최고를
          받았음에도 불구하고, 그 최고를 받은 날로부터 2주일이 경과하도록
          미지급한 요금을 전액 변제하지 아니하는 경우
        </P3>
        <P3>
          - 회원이 연속적 또는 비연속적으로 연 2회 이상 서비스 이용 요금
          지급의무의 이행을 지체하였거나 거부하는 경우
        </P3>
        <P3>
          - 회원이 신용 카드회사를 포함한 금융기관으로부터 부도처분, 거래정지,
          지불정지, 이용 정지 등을 당하는 등 신용이 악화된 경우
        </P3>
        <P3>
          - 회원의 워크아웃, 화의, 회사정리, 파산절차 개시 등의 신청으로
          정상적인 영업활동을 할 수 없게 된 경우
        </P3>
        <P3>
          - 회원에 대한 압류, 가압류, 경매신청, 체납절차 등의 신청으로 정상적인
          영업활동을 할 수 없게 될 우려가 있거나 회사가 회원으로부터 서비스 이용
          요금을 전액 지급 받을 수 없게 될 우려가 있는 경우
        </P3>
        <P3>
          - 사용자의 사이트가 국내법에 위반되어 회사에 피해가 예상되는 경우
        </P3>
        <P3>
          - 고의적으로 회사의 서비스에 장애를 일으키기 위하여 한 행동으로
          판단되는 경우
        </P3>
        <P3>- 본 약관을 위반한 경우</P3>
        <P3>- 기타 회사가 필요하다고 판단되는 경우</P3>
      </P3>
      <P3>
        5. 회원은 원하는 시기에 회사가 제공하는 방법을 통하여 서비스 해지 요청을
        할 수 있습니다. 단, 미납요금 정산이 완료되지 않을 경우 해지 할 수
        없습니다.
      </P3>
      <P3>
        6. 회사는 회원이 위 5항을 이행할 경우 관계법령 및 개인정보처리방침에
        따라 회사가 회원 정보를 보유하는 경우를 제외하고는 회원에게 제공된 모든
        자원이 회수되며 서비스 상에 저장되어 있는 모든 게시물, 정보 및 자료는
        삭제됩니다.
      </P3>

      <H4>제 11조. 서비스 이용요금 및 결제</H4>

      <P3>
        1. 회사는 유료서비스 제공의 대가로 회원으로부터 서비스 이용요금을 지급
        받습니다.
      </P3>
      <P3>
        2. 서비스 이용요금의 세부 내역은 회사의 홈페이지에
        명기합니다.(https://voda.nerdfactory.ai)
      </P3>
      <P3>
        3. 회원은 정확한 요금청구서의 수령을 위해, 전화번호, 전자우편주소 등을
        항상 최신의 상태로 유지할 책임이 있습니다.
      </P3>
      <P3>
        4. 회사는 미납한 연체요금에 대하여 다음 요금청구 시 합산하여 청구할 수
        있습니다.
      </P3>
      <P3>
        5. 회사와 회원이 유료서비스의 이용에 대해 별도의 계약을 체결한 경우 별도
        계약 내용이 우선합니다.
      </P3>

      <H4>제 12조. 요금의 산정 및 계산 방법</H4>

      <P3>
        1. 유료서비스 이용요금은 회사가 사용자에게 유료서비스 제공을 개시한
        시점으로부터 산정됩니다.
      </P3>
      <P3>
        2. 일일 요금의 계산은 00시 00분부터 24시 00분까지를 1일로 하며, "서비스"
        제공의 개시시간 또는 종료시간이 1일의 중도인 경우에는 이를 1일로
        간주합니다.
      </P3>
      <P3>
        3. 사용자가 유료서비스를 중도 해지 시에는 월 이용요금 납부 대상 기간
        종료시점까지는 VODA 서비스를 지속적으로 이용할 수 있습니다. 관련 법률이
        허용하는 범위에서, 결제 금액은 환불되지 않으며 한 달 중 유료서비스를
        이용하지 않은 기간에 대한 환불은 이뤄지지 않습니다.
      </P3>
      <P3>
        4. 유료서비스에 할당된 PV는 잔여 용량이 남았다고 하더라도 익월로
        이월되지 않습니다.
      </P3>
      <P3>5. 유료서비스 이용 요금은 1개월 단위로 이용 요금을 산정합니다.</P3>
      <P3>
        6. 사용자가 서비스 이용 중에 상위 유료서비스로 변경할 경우 변경된
        유료서비스 이용요금에서 변경 전 유료서비스에 할당된 PV 중 미사용된 PV
        만큼 할인됩니다.
      </P3>
      <P3>7. 모든 유료서비스 이용요금은 부가가치세 별도입니다.</P3>

      <H4>제 13조. 이용요금의 청구</H4>

      <P3>
        1. 회사는 유료서비스 이용요금을 사용자가 지정한 정기결제일에 청구합니다.
      </P3>
      <P3>
        2. 회사는 유료서비스 이용요금 지급 방법으로 신용카드 결제수단을
        제공합니다.
      </P3>
      <P3>
        3. 유료서비스의 경우 일정 주기마다 이용자가 입력한 결제수단 등의
        결제정보로 유료서비스를 자동으로 구매하는 자동결제를 원칙으로 합니다.
      </P3>
      <P3>
        4. 정기결제일은 1일~28일 중 사용자가 선택 할 수 있으며, 사용자의 상황에
        따라 정기결제일은 변경할 수 있습니다. 변경된 정기결제일은 다음
        유료서비스 결제에 적용됩니다.
      </P3>
      <P3>
        5. 회사는 이용자가 이용요금을 정기결제일에 납부하지 아니하였을 때
        유료서비스 제공을 중단합니다.
      </P3>
      <P3>
        6. 이용자의 유료서비스 이용요금이 이용기간 중 납부되지 않을 경우 회사는
        사전 통보 없이 유료서비스 제공을 정지할 수 있으며, 이에 따른 이용자의
        손해에 대해서 회사는 어떠한 책임도 지지 않습니다.
      </P3>
      <P3>
        7. 회원은 정확한 요금청구서의 수령을 위해, 전화번호, 전자우편주소 등을
        항상 최신의 상태로 유지할 책임이 있습니다.
      </P3>

      <H4>제 14조. 이용요금에 대한 이의신청</H4>

      <P3>
        1. 회원은 회사가 청구한 서비스 이용요금에 이의가 있는 경우 결제일로부터
        2주 이내 이의를 제기할 수 있으며, 이 경우 회사와 회원의 합의하에 요금을
        재정산하여 청구 할 수 있습니다.
      </P3>
      <P3>
        2. 회사는 이의 신청 접수 후 15일 이내에 처리 결과를 사용자에게 통지해야
        하며, 부득이한 사유로 기간 내에 처리하지 못한 경우에는 처리기간을 재
        산정하여 사용자에게 통지 합니다.
      </P3>

      <H4>제 15조. 과오금</H4>

      <P3>
        1. 회사는 사용자가 이용요금을 결제함에 있어서 과오금을 지급한 경우 그
        과오금을 사용자에게 반환합니다. 단, 사용자가 동의하거나 회사의 과오금
        환급 통지에 대하여 사용자가 응하지 않을 경우에는 익월 이용요금에서 해당
        금액을 차감하여 청구할 수 있습니다.
      </P3>
      <P3>
        2. 회사는 과오금을 반환하여 할 사용자에게 미납요금 등이 있을 경우에는
        반환하여야 할 과오금에서 미납 요금 등을 우선 공제 하고 반환할 수
        있습니다.
      </P3>

      <H4>제 16조. 회사의 의무</H4>

      <P3>
        1. 회사는 관련법과 이 약관을 준수하며, 계속적이고 안정적으로 서비스를
        제공하기 위하여 최선을 다하여 노력합니다.
      </P3>
      <P3>
        2. 회사는 서비스 제공과 관련하여 알고 있는 회원의 신상정보를 본인의 승낙
        없이 제3자에게 누설, 배포하지 않습니다. 단, 관계법령에 의한 수사상의
        목적으로 관계기관으로부터 요구 받은 경우나 정보통신윤리위원회의 요청이
        있는 경우 예외로 합니다.
      </P3>
      <P3>
        3. 회사는 서비스와 관련한 회원으로부터 제기된 의견이나 불만사항이
        정당하다고 인정될 경우 이를 신속하게 처리하여야 하며, 신속한 처리가
        곤란한 경우 그 사유와 처리 일정을 전자우편 등을 통하여 동 회원에게
        통지합니다.
      </P3>
      <P3>
        4. 회사는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법,
        전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.
      </P3>

      <H4>제 17조. 회원의 의무</H4>

      <P3>
        1. 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한
        주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업 무에
        방해되는 행위를 하여서는 안됩니다.
      </P3>
      <P3>
        2. 회원은 서비스 이용과 관련하여 다음 각 호에 해당되는 행위를 하여서는
        아니되며, 다음 각 호의 행위를 하는 경우 회사는 해당 회원의 서비스 이용을
        중지하거나 이용계약을 해지할 수 있습니다.
        <P3>- 다른 회원의 계정을 도용, 부정하게 사용하는 행위</P3>
        <P3>
          - 회사의 지적재산권, 제 3자의 지적재산권 등 기타 권리를 침하는 행위
        </P3>
        <P3>- 서비스에 게시된 정보를 복제, 변경, 훼손하는 행위</P3>
        <P3>
          - 회사가 안내하는 방법 이외의 다른 방법을 사용하여 서비스 및 관련
          장비(통신 장비, 통신 회선, 서버, 기타 장비 및 소프트웨어 등)에
          접근하는 행위
        </P3>
        <P3>
          - 서비스를 통하여 얻은 분석 정보 또는 자료를 회사의 명시적인 서면동의
          없이 제 3자에게 양도, 대여, 이전, 공개하는 행위
        </P3>
        <P3>
          - 회사의 서비스, 소프트웨어 및 서버가 정상적으로 운영되는 것을
          방해하거나 방해할 우려가 있는 장치 또는 소프트웨어를 사용하는 행위
        </P3>
        <P3>- 기타 불법적이거나 관계법령 및 약관에 위배되는 행위</P3>
      </P3>
      <P3>
        3. 회원이 이용하는 복수의 서비스 계정 중 하나 이상의 계정이 제 2항을
        위반하는 경우, 회사는 해당 회원이 이용하는 모든 계정에 대해서 서비스
        중단 또는 제한 조치를 취할 수 있습니다.
      </P3>
      <P3>
        4. 회원은 회사가 약관 위반 여부를 확인하기 위하여 자료 또는 접근권한의
        제공 및 관련 사실에 대한 소명을 요청하는 경우 그에 대하여 성실하게
        응하여야 합니다.
      </P3>
      <P3>
        5. 회원은 서비스 이용을 위해 현재의 사실과 일치하는 완전한 등록정보를
        제공해야 합니다.
      </P3>

      <H4>제 18조. 개인정보보호</H4>
      <P3>
        회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의
        개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는
        관련 법령 및 회사가 정하는 "개인정보취급방침"에 정한 바에 의합니다.
      </P3>

      <H4>제 19조. 지적재산권</H4>

      <P3>
        1. 서비스의 제공 및 이용과 관련하여 발생한 콘텐츠(관련 소프트웨어,
        분석정보 및 관련 자료 등을 포함) 등 일체의 산출물에 대한 지적재산권은
        회사에게 귀속됩니다. ("지적 재산권"이라 함은 저작권, 인격권, 상표,
        트레이드 드레스, 특허, 영업 기밀, 불공정 경쟁, 개인정보 보호 권리, 공개
        권리, 그리고 기타 재산 권리를 의미합니다.)
      </P3>
      <P3>
        2. 이 약관에 기한 서비스 이용 과정에서 발생하는 데이터 정보 기타 일체의
        산출물에 대한 지식재산권은 회사에 귀속합니다. VODA는 수집된 데이터를
        재가공하여 VODA 이용 사례 또는 콘텐츠로 사용할 수 있으며, 데이터의 출처
        및 구체적인 사항은 공개 되지 않습니다.
      </P3>
      <P3>
        3. 회원은 본 약관에서 명시적으로 허용하는 범위 내에서 제1항의 정보 및
        자료, 제2항의 산출물을 이용할 수 있으며, 서비스 이용과 관련하여 회사의
        지식재산권을 침해하여서는 아니 됩니다.
      </P3>

      <H4>제 20조. 손해배상</H4>

      <P3>
        1. 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는
        경우, 이 약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상하여야
        합니다.
      </P3>
      <P3>
        2. 회사는 서비스 요금이 무료인 서비스의 이용과 관련하여 이용자에게
        발생한 손해에 대해 어떠한 책임도 지지 않습니다.
      </P3>
      <P3>
        3. 회사의 귀책사유로 회원이 서비스를 원활하게 이용하지 못하여 손해가
        발생한 경우 회사에게 손해배상을 청구할 수 있으며, 그 범위는 회원이
        신청하여 이용하고 있는 유료 서비스의 내용별로 한정합니다.
      </P3>
      <P3>
        4. 회원이 손해배상을 청구하고자 하는 경우 이에 대한 사유 및 장애를
        증명한 가능한 내용으로 기재하여 서면 신청하여야 합니다.
      </P3>
      <P3>
        5. 손해배상 청구는 그 청구사유를 안 날로부터 3개월 내에 행사하지 않으면
        그 청구권이 소멸합니다. 청구사유가 발생한 날로부터 6개월이 경과한 때에도
        이와 같습니다.
      </P3>

      <H4>제 21조. 면책</H4>

      <P3>
        1. 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여
        서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
      </P3>
      <P3>
        2. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로
        제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
      </P3>
      <P3>
        3. 회사는 회원의 귀책사유로 인한 서비스 이용장애에 대하여 책임을 지지
        않습니다.
      </P3>
      <P3>
        4. 회사는 회원이 서비스를 통해 기대하는 수익 및 효과를 얻지 못하거나
        상실한 것에 대하여 책임을 지지 않습니다.
      </P3>
      <P3>
        5. 회원이 서비스를 통해 제공받은 분석 정보나 자료를 이용하여 사업판단
        또는 영업활동을 행하는 것은 전적으로 회원의 책임하에 이루어지며, 회사는
        이와 관련하여 어떠한 책임도 지지 않습니다.
      </P3>
      <P3>
        6. 회사는 이 약관에 명시적으로 규정하지 않은 사항에 관하여 회원에게
        어떠한 보장 또는 약정하지 않습니다.
      </P3>
      <P3>
        7. 회사는 서비스를 있는 그대로 제공하며 분석 정보, 자료, 데이터의
        신뢰도, 정확성, 이용 가능성 등에 대하여 어떠한 책임도 부담하지 않습니
        다.
      </P3>
      <P3>
        8. 회사는 회원의 귀책사유로 인하여 회원 또는 제 3자에게 손해가 발생한
        경우 어떠한 책임도 지지 않습니다. 위 사유로 인하여 회사와 제 3자 사이에
        분쟁이 발생하는 경우 회원은 자신의 비용과 책임으로 분쟁을 해결하며 이와
        관련하여 발생한 회사의 의무 또는 책임(변호사 보수 지급 의무 등 포함)을
        면책합니다.
      </P3>
      <P3>
        9. 회사는 무료로 제공되는 서비스와 관련하여 관련 법령에 특별한 규정이
        없는 한 회원에 대하여 법적 책임을 부담하지 않습니다.
      </P3>

      <H4>제 22조. 재판관할</H4>

      <P3>
        1. 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와
        회원은 분쟁의 해결을 위해 성실히 협의합니다.
      </P3>
      <P3>
        2. 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 회사의 본사
        소재지를 관할하는 법원을 전속 관할법원으로 합니다.
      </P3>

      <H4>부칙. (시행일) 본 약관은 2021년 08월 01일부터 시행합니다.</H4>
    </Component>
  );
};

export default TermsOfUse;
