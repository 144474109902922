import React from 'react';
import styled from '@emotion/styled';
import CheckLabel from 'components/Molecule/Label/CheckLabel';

const Component = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const CheckLabelWrapper = styled(CheckLabel)`
  margin-right: 64px;
  &:last-child {
    margin-right: 0px;
  }
`;

interface CheckLabelListProps {
  className?: string;
  data: {
    title: string;
  }[];
}

const CheckLabelList = ({ className, data }: CheckLabelListProps) => {
  return (
    <Component className={className}>
      {data.map((element) => {
        return <CheckLabelWrapper title={element.title} key={element.title} />;
      })}
    </Component>
  );
};

export default CheckLabelList;
