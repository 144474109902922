import { css } from '@emotion/react';
import styled from '@emotion/styled';

const maxWidth = (gridNumber: number) => {
  switch (gridNumber) {
    case 1:
      return css`
        max-width: 8.33%;
        flex-basis: 8.33%;
      `;
    case 2:
      return css`
        max-width: 16.66%;
        flex-basis: 16.66%;
      `;
    case 3:
      return css`
        max-width: 25%;
        flex-basis: 25%;
      `;
    case 4:
      return css`
        max-width: 33.33%;
        flex-basis: 33.33%;
      `;
    case 5:
      return css`
        max-width: 41.666667%;
        flex-basis: 41.666667%;
      `;
    case 6:
      return css`
        max-width: 50%;
        flex-basis: 50%;
      `;
    case 7:
      return css`
        max-width: 58.333333%;
        flex-basis: 58.333333%;
      `;
    case 8:
      return css`
        max-width: 66.666667%;
        flex-basis: 66.666667%;
      `;
    case 9:
      return css`
        max-width: 75%;
        flex-basis: 75%;
      `;
    case 10:
      return css`
        max-width: 83.333333%;
        flex-basis: 83.333333%;
      `;
    case 11:
      return css`
        max-width: 91.666667%;
        flex-basis: 91.666667%;
      `;
    case 12:
      return css`
        max-width: 100%;
        flex-basis: 100%;
      `;

    default:
      return css``;
  }
};

export const ItemGrid = styled.div<{
  desktop?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  bigscreen?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}>`
  flex-grow: 0;
  ${(props) => {
    if (props.desktop) {
      return css`
        @media ${props.theme.device.desktop} {
          ${maxWidth(props.desktop)}
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.bigscreen) {
      return css`
        @media ${props.theme.device.bigscreen} {
          ${maxWidth(props.bigscreen)}
        }
      `;
    }
    return css``;
  }}
`;

export const ContainerGrid = styled.div<{
  spacing?: 'xxs' | 'xs' | 'xsm' | 'sm' | 'md' | 'xlg' | 'lg' | 'xl';
}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  ${(props) => {
    if (props.spacing) {
      return css`
        width: calc(100% + ${props.theme.spacing[props.spacing] * 2}px);
        margin: -${props.theme.spacing[props.spacing]}px;
        ${ItemGrid} {
          padding: ${props.theme.spacing[props.spacing]}px;
        }
      `;
    }
    return css`
      padding: 0px;
    `;
  }}
`;

export const ContentsLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
